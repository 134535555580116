import moment from 'moment';
import '../AMPMFront/NewProductModal.css'
import {Tooltip} from 'antd';
import {Context} from '../../../Store';
import {TagsFilled} from '@ant-design/icons';
import {Utils} from '../../../../services/Utils.class';
import {FormattedMessage, useIntl} from 'react-intl';
import {PurchaseProduct} from '../../actions/PurchaseProduct';
import {UpOutlined, DownOutlined} from '@ant-design/icons';
import React, {useContext, useEffect, useState} from 'react';
import {ProductInterface} from '../../../product/ProductInterface';
import {HeartOutlined, ShareAltOutlined} from '@ant-design/icons';
import {AddAndRemoveProduct} from '../../actions/AddAndRemoveProduct';
import {Button, Comment, Form, Image, Input, List, Modal, Rate, Divider} from 'antd';
import {AMPMPurchaseProduct} from '../../actions/AMPMPurchaseProduct';
import {MaxPurchaseProduct} from '../../actions/MaxPurchaseProduct';
import {MaxAddAndRemoveProduct} from '../../actions/MaxAddAndRemoveProduct';

export function MaxProductModal (props:{
  item: any,
  products: any,
  visible:boolean
  setShowModal:Function
  product:ProductInterface
}){
  const intl = useIntl();
  const {TextArea} = Input;
  const names:string = 'Anonymous';
  const [state, dispatch] = useContext(Context);
  const [value, setValue]= useState<number>(0);
  const [details, setDetails]= useState<any>({});
  const [showCombo, setShowCombo]= useState<any>({});
  const [submitting, setSubmitting] = useState(false);
  const [valueComment, setValueComment] = useState('');
  const [comments, setComments] = useState<CommentItem[]>([]);
  const [isProductInFavorite, setIsProductInFavorite] = useState<any>();
  const [selectingCombo, setSelectingCombo]= useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductInterface>(props.product);
  const numbers:any = ['131', '412', '227', '144', '123', '403', '033', '032', '838', '882'];
  const cart = state.merchantParams?.noClient ? (state.cart[state.merchantParams.noClient] ? state.cart[state.merchantParams.noClient] : state.cart[0]) : state.cart[0];

  interface CommentItem {
    author:string;
    datetime:string;
    content:React.ReactNode;
  }

  useEffect(() => {
    const productIndex:number = getProductsInFavorite();
    if (productIndex === -1) setIsProductInFavorite(false);
    else setIsProductInFavorite(true);
  }, []);





  const getTotalPriceProduct = (product:any) => {
    let total = product.price * (cart[product.id] ? cart[product.id] : 1);
    if (product.promo && Object.keys(product.promo).length) {
      const {type, value, percent} = product.promo;
      if (type === 'regular' && !product.promoPrice && cart[product.id] >= 1) {
        let productPriceDiscount = ((product.price / 100) * percent);
        total = (product.price - productPriceDiscount) * cart[product.id]
      }
      if (type === 'quantity' && !product.promoPrice && cart[product.id] >= Number(value)) total -= (product.price / 100) * percent;
    }
    return total;
  };

  const setFavoriteProduct = () => {
    const indexProduct:number = getProductsInFavorite();
    if (indexProduct === -1) {
      const favorites = state.favorites ? [...state.favorites, props.product] : [props.product];
      dispatch({
        payload: favorites,
        type: 'CURRENT-FAVORITES'
      });
      setIsProductInFavorite(true);
    } else {
      state.favorites.splice(indexProduct, 1);
      dispatch({
        type: 'CURRENT-FAVORITES',
        payload: [...state.favorites]
      });
      setIsProductInFavorite(false);
    }
  }

  const getNoIvaPrice = (product:any)=>{
    return( parseFloat(product.models[0].price) / (1 + (product.models[0].details.find((e:any)=>e.type==="impuesto").value/100)))
  }

  const getProductsInFavorite = () => {
    let productIndex = -1;
    if (state.favorites) productIndex = state.favorites.findIndex((favorite:any) => favorite.id === props.product.id);
    return productIndex;
  }

  const getClient = (names:string, numbersA:[string]) => {
    const client = {username: ''};
    const randomIndexNumbers = Math.floor(Math.random() * (numbersA.length));
    const username = `${names} ${numbers[randomIndexNumbers]}`;

    if (username) {
      client.username = username;
      return client;
    }
  }

  const CommentList = ({comments}:{comments:CommentItem[]}) => (
    <List
      dataSource={comments}
      itemLayout='horizontal'
      renderItem={props => <Comment {...props} />}
      header={`${comments.length} ${comments.length > 1 ? intl.formatMessage({id:'MERCHANT_COMMENTS'}) : intl.formatMessage({id:'MERCHANT_COMMENT'})}`}
    />
  );

  const handleSubmit = () => {
    let username:any = getClient(names, numbers);
    if (!valueComment.trim()) return;

    setSubmitting(true);

    setTimeout(() => {
      setSubmitting(false);
      setValueComment('');
      setComments([
        ...comments,
        {
          author: username.username,
          datetime: moment().fromNow(),
          content: <p>{valueComment.trim()}</p>
        },
      ]);
    }, 500);
  };

  const handleChange = (e:any) => {
    setValueComment(e.target.value);
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: intl.formatMessage({id:'MERCHANT_SHARE_PRODUCT_TITLE'}),
        url: window.location.href + `?product=${props.product.id}`,
        text: intl.formatMessage({id:'MERCHANT_SHARE_PRODUCT_TEXT'})
      }).then(() => {
        console.log('Successfully shared');
        navigator.clipboard.writeText(`${window.location.href}?product=${props.product.id}`)
      }).catch(error => {
        console.error('Something went wrong sharing the blog', error);
      });
    }
  };

  const quantity = props.product ? (props.product.quantity - (cart[props.product.id] ? cart[props.product.id] : 0)) : 0;
  let message =     quantity===0? 'Agotado':''
  if (props.product?.quantity !== -1) {
    message =  !props.hideAvailableStock
      ? quantity + (quantity > 0 ? '+' : '') + (quantity == 1 ? intl.formatMessage({id:'CASHIER_ACTIONS__LABEL_AVAILABLE'})
      : intl.formatMessage({id:'CASHIER_ACTIONS__LABEL_AVAILABLE_PLURAL'})) : '';

  }

  let img = (
    <div className={'modal-detail-item-img'}>
      <i
        id={`Cashier-item-img-${props.product.name}`}
        className={'large material-icons Customer-merchant-icon'}
      >loyalty</i>
    </div>
  );

  if (props.product.imgId !== '') {
    img = (
      <div className={'modal-detail-container-img-cont'}>
      <Image
        width={265}
        className={'modal-detail-container-img-size'}
        src={`https://ciisa-images.s3.amazonaws.com/PRODUCTS/${props.product.code}.png`}
      />
      </div>
    );
  }

  const getDiscount = (price:number, discount:any) => {
    let discountToDiv = discount * 100;
    return (100 - Math.floor(discountToDiv / price));
  }
  let price = (
    <p id={`Cashier-item-price-${props.product.id}`} className={'Max-modal-detail-Price'}>
      {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(getNoIvaPrice(props.item), state.ruleParams.urlStockEnable ? 0 : 2)}
    </p>
  );

  let promoPrice = (
    props.product.promoPrice && props.product.promoPrice > 0 ? (
        <p id={`Cashier-item-price-${props.product.id}`} className={'New-modal-detail-promoPrice'}>
          {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney((cart[props.product.id] ? cart[props.product.id] : 1) * props.product.promoPrice, state.ruleParams.urlStockEnable ? 0 : 2)}
        </p>
      )
      : <p/>
  );

  return (
    <Modal
      style={{fontFamily: state.currentMerchant.styles.font, top: 20}}
      visible={props.visible}
      onCancel={() => {props.setShowModal(false)}}
      footer={!cart[props.product?.id] ? ( [<MaxPurchaseProduct modal key={props.product.id} hideAvailableStock product={selectedProduct}/>]) : ([<MaxAddAndRemoveProduct key={props.product.id} message={message} product={props.product}/>])}
    >
      <div className={'New-modal-detail-container'}>

        <div className={'New-modal-detail-container-img'}>
          {img}
          {(props.product.promo && Object.keys(props.product.promo).length > 0) || props.product.promoPrice && props.product.promoPrice > 0 ?
            <div className={'New-modal-Icon-Outlined-Gift'}>
              <Tooltip title={props.product.promo.description}>
                <TagsFilled style={{fontSize: '34px'}}/>
              </Tooltip>
              {state.ruleParams?.promotionIconText &&
                <span className={'New-Icon-Promotion-Text'}>{`${Math.floor(props.product.promo.percent)}%`}</span>
              }
            </div> : <></>
          }
        </div>
         <div>
          <div className={'Max-modal-detail-container-rateAndPrice'}>
            <div>
              <p id={`Cashier-item-price-${props.product.id}`} className={'Max-modal-detail-Name'}>{props.product.description}</p>
              {!state.ruleParams?.hideScoreAndComments &&
                <span><Rate className={'New-modal-detail-rate'} onChange={setValue} value={value}/></span>
              }
              {props.product.promoPrice && props.product.promoPrice > 0? promoPrice:  props.product.details.find(e=>e.type=="indPeso").value == "S"? <p id={`Cashier-item-price-${props.product.id}`} className={'Max-modal-detail-Price'}>
                {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(getNoIvaPrice(props.item), state.ruleParams.urlStockEnable ? 0 : 2)+" por Kg"}
              </p>: price}
              {props.product.promoPrice && props.product.promoPrice > 0? price : <></>}

            </div>
            <div>
              <div className={isProductInFavorite ? 'New-modal-favorite-selected' : 'New-modal-favorite'} onClick={setFavoriteProduct}><HeartOutlined/></div>
              <span className={'New-modal-favorite'} onClick={handleShare}><ShareAltOutlined/></span>
            </div>
          </div>
           <div className={'detailContainerMax'}>
             <p className={'Max-Modal-Product-Description2'}>{state.currentMerchant.styles?.style ? 'Ref.' : `${intl.formatMessage({id:'MERCHANT_ADD_CODE_PRODUCT'})}`}: {props.product.code}.</p>
             <p className={'Max-Modal-Product-Description2'}>{props.product.details.find(e=>e.type==='medida')? `Medida: ${props.product.details.find((e:any)=>e.type==='medida')?.value}` :''}</p>
             {props.product.details.find(e => e.type == 'indPeso').value == 'S' ?
               <p className={'Max-Modal-Product-Description3'}> Este producto se factura por kilo,
                 por favor ingresar al carrito la cantidad de kilos deseados.</p> : <></>}
           </div>
         </div>

      </div>
    </Modal>
  )
}